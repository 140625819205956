import _ from "lodash";
import React from "react";
import greenArrow from "../assets/greenArrows.png";
import redArrow from "../assets/redArrows.png";


const Transaction = ({ transaction }) => {
  const { reference, created_at, amount } = transaction;

  const isPostive = !amount.toString().includes("-");

  let receiver;
  
  if (amount > 0){
      receiver = "Me";
  } else {
      receiver = _.startsWith(reference, 'Ticket') || _.startsWith(reference, 'Commission') ? 'SENSO' : _.startsWith(reference, 'Cotisation') || _.startsWith(reference, 'Impot') ? 'TAX' : _.startsWith(reference, 'Salary') ? 'Me' : '';
  }

  return (
    <div className="transaction">
      <img src={isPostive ? greenArrow : redArrow} alt="transaction arrow" />
      <div className="transactionDetails content">
        <div className="elem"><h6>to {receiver} </h6></div>
        <div className="elem">{reference} </div>
        <div className="elem small-text">{created_at.split('T')[1].substring(0, 5)} </div>
      </div>


      <h3
        className={`amount ${isPostive ? "positiveAmount" : "negativeAmount"}`}
      >
        {isPostive ? "+" + amount : amount} €
      </h3>
    </div>
  );
};

export default Transaction;
