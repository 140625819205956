import _ from "lodash";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import TransactionsByDate from "./TransactionsByDate";

const Transactions = ({ transactions, transactionsToShow, link, linkText }) => {

  const [groupedTransactions, setGroupedTransactions] = useState({});

  useEffect(() => {
    const gt = _.groupBy(transactions, (transaction) => {
      return transaction.created_at.split("T")[0];
    });
    setGroupedTransactions(gt)
  }, [transactions]);

  return (
    <div className="transactions">

      {<Link to={link}>{linkText}</Link>}
      {_.size(transactions) == 0 && <span className={"alert alert-light text-center w-100"} role="alert">No transactions to display...</span>}

      <div className="transactionsSection">
        {Object.keys(groupedTransactions)
          .slice(0, transactionsToShow || Object.keys(transactions).length)
          .map((item) => {
            return (
              <TransactionsByDate
                transactions={groupedTransactions[item]}
                date={item}
              />
            );
          })}
      </div>
    </div>
  );
};

export default Transactions;
