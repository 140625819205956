import { onAuthStateChanged, signInWithEmailAndPassword } from "firebase/auth";
import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { auth } from "../firebase-config";

function Login() {

  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [error, setError] = useState();
  const [user, setUser] = useState({});
  let navigate = useNavigate();

  onAuthStateChanged(auth, (currentUser) => {
    setUser(currentUser);
  });


  const login = async (e) => {
    e.preventDefault();
    try {
      const user = await signInWithEmailAndPassword(
        auth,
        loginEmail,
        loginPassword
      );
      handleClick();
    } catch (error) {
      setError('Invalid username or password')
    }
  };


  const handleClick = () => {
    navigate("/account");
  }

  return (
    <div className="account">
      <h1 className="dashboard-title">Sign-in with MySenso</h1>
      <form className="login" onSubmit={login}>
        <div className="mb-3">
          <br />
          <div className="wrapper">
            <i className="bi bi-envelope-fill icon"></i>
            <input
              type="email"
              required="true"
              className="form-control input"
              placeholder="Enter email"
              onChange={(event) => {
                setLoginEmail(event.target.value);
              }}
            />
          </div>
        </div>

        <div className="mb-3">
          <label></label>
          <div className="wrapper">
            <i className="bi bi-lock-fill icon"></i>

            <input
              type="password"
              required="true"
              className="form-control input"
              placeholder="Enter password"
              onChange={(event) => {
                setLoginPassword(event.target.value);
              }}
            />
          </div>
          <br />
        </div>



        <div className="d-grid">
          <button type="submit" className="btn btn-primary">Sign-in</button>
          {error ? <span style={{ color: "red" }}>{error}</span> : null}
        </div>
      </form>
    </div>
  )
}



export default Login;