import { Navigate, Outlet } from "react-router-dom";

const PrivateRoute = ({ authenticated }) => {

    return authenticated ? <Outlet /> : <Navigate to="/sign-in" />;
};

export default PrivateRoute;


