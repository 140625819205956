import React from "react";
import Transaction from "./Transaction";

const TransactionsByDate = ({ transactions, date }) => {
  const isToday =
    new Date(date).toLocaleDateString() === new Date().toLocaleDateString();

  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  return (
    <div className="transactionByDate">
      <p>
        {isToday
          ? "Today"
          : new Date(date).toLocaleDateString("fr-FR", options)}
      </p>
      <div className="transactionsBloc">
        {transactions.map((transaction) => {
          return <Transaction transaction={transaction} />;
        })}
      </div>
    </div>
  );
};

export default TransactionsByDate;
