import { signOut } from "firebase/auth";
import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { auth } from "../firebase-config";
import Logo from "./Logo";
import "../styles/NavBar.css";


function NavBar({ user }) {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  let navigate = useNavigate();
  const logout = async () => {
    await signOut(auth);
    navigate("/sign-in");
  };

  return (
    <>
      <nav className="navbar">
        <div className="nav-container">
          <NavLink exact to="/" className="nav-logo">
            <Logo></Logo>
            <i className="fas fa-code"></i>
          </NavLink>
          {user && <div>
            <ul className={click ? "nav-menu active" : "nav-menu"}>
              <li className="nav-item">
                <NavLink
                  className="nav-links"
                  onClick={logout}
                >
                  LOGOUT
                </NavLink>
              </li>
            </ul>
            <div className="nav-icon" onClick={handleClick}>
              <i className={click ? "bi bi-x-circle" : "bi bi-list"}></i>
            </div>
          </div>}
        </div>


      </nav>
    </>
  );
}

export default NavBar;