
import _ from "lodash";
import React from "react";


const Account = ({ account }) => {
  const format = num => String(num).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,');

  return (
    <div className="account">
      <h1 className="dashboard-title">
        Senso dashboard
      </h1>
      <div className="balance">
        <h3>Balance</h3>
        <h2 className={`amount ${!account?.balance.toString().includes("-") ? "positiveAmount" : "negativeAmount"}`}
        > {format(account?.balance)} €</h2>
      </div>
    </div>
  );
};



export default Account;
