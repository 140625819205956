import _ from "lodash";
import React, { useEffect, useState } from "react";
import search from "../assets/search.png";
import Transactions from "./Transactions";

const AllTransactions = ({ transactions }) => {
  const [myTransactions, setMyTransactions] = useState(transactions);

  useEffect(() => {
    setMyTransactions(transactions);
  }, [transactions]);

  const filter = (value) => {
    const trs = transactions.filter(tr => {
      return _.includes(_.lowerCase(tr.reference) + ' ' + tr.amount, _.toString(value).toLocaleLowerCase());
    });
    setMyTransactions(trs);
  };

  return (

    <div className="allTransactions">
      <h1 className="dashboard-title">
        Transactions history
      </h1>
      <div className="search">
        <input
          type="search"
          name="searchBar"
          placeholder="Search for a transaction"
          onChange={e => filter(e.target.value)}
        />
        <img src={search} alt="search icon" />
      </div>
      <Transactions
        transactions={myTransactions}
        link={"/account"}
        linkText={"Go Back To Dashboard"}
      />

    </div>
  );
};

export default AllTransactions;
