import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore"



// dev
const devFirebaseConfig = {
  apiKey: "AIzaSyAU21tqvzjQMjl8dlGKtzetyZd9Wk3nck8",
  authDomain: "pay-emp.firebaseapp.com",
  projectId: "pay-emp",
  storageBucket: "pay-emp.appspot.com",
  messagingSenderId: "339061414304",
  appId: "1:339061414304:web:60bc81894901c6a500ecc5"
};

// prod
const prodFirebaseConfig = {
  apiKey: "AIzaSyA2wSwPGcsdsH40TkNBpl0MHIYMatibNog",
  authDomain: "senso-payroll.firebaseapp.com",
  projectId: "senso-payroll",
  storageBucket: "senso-payroll.appspot.com",
  messagingSenderId: "935886978415",
  appId: "1:935886978415:web:a6d63535eaa9738d8c7c9a",
  measurementId: "G-0VCPQDCHB8"
};


console.log(process.env.ENV);

const app = initializeApp(process.env.ENV === 'production' ? prodFirebaseConfig : devFirebaseConfig);

export const auth = getAuth(app);


export const db = getFirestore(app);

export default db;