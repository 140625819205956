import 'bootstrap-icons/font/bootstrap-icons.css';
import { onAuthStateChanged } from "firebase/auth";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { RingLoader } from 'react-spinners';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

import './App.css';
import AllTransactions from "./components/AllTransactions";
import Home from "./components/Home";
import IntelligentRoute from './components/IntelligentRoute';
import Login from "./components/Login";
import NavBar from './components/NavBar';
import NotFound from './components/NotFound';
import PrivateRoute from "./components/PrivateRoute";
import PublicRoute from './components/PublicRoute';
import db, { auth } from './firebase-config';
import "./styles/App.scss";

function App() {

  const [groupedTransactions, setGroupedTransactions] = useState({});
  const [authentication, setAuthState] = useState({
    authenticated: false,
    initialized: true
  });
  const [account, setAccount] = useState(undefined);
  const [user, setUser] = useState(undefined);
  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };

  useEffect(() => {
    if (user) {
      const ref = query(
        collection(db, "accounts"),
        where("userUid", "==", user.uid)
      );
      const unsubscribe = onSnapshot(ref, snap => {
        const data = snap.empty ? { balance: 0, transactions: [] } : snap.docs[0].data();
        setAccount(data)
        setGroupedTransactions(data.transactions);

      });
      return unsubscribe;
    }
  }, [user]);


  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
        setAuthState({
          authenticated: true,
          initialized: false
        });
      } else if (!user) {
        setUser(undefined);
        setAuthState({
          authenticated: false,
          initialized: false
        });
      }
    });
  }, [setAuthState])





  if (authentication.initialized) {
    return (<div className='senso-spinner'> <RingLoader
      color={'#000000'}
      loading={true}
      cssOverride={override}
      size={150}
      aria-label="Loading Spinner"
      data-testid="loader"
    />
    </div>)
  }



  return (
    <Router>
      {<NavBar user={user} />}
      <div className="App">
        <div className="auth-wrapper">
          <div className="auth-inner">
            <Routes>
              <Route exact path="/account" element={<PrivateRoute authenticated={authentication.authenticated} />}>
                <Route exact path='/account' element={<Home transactions={groupedTransactions} account={account} />} />
              </Route>
              <Route exact path="/transactions" element={<PrivateRoute authenticated={authentication.authenticated} />}>
                <Route exact path="/transactions" element={<AllTransactions transactions={groupedTransactions} />} />
              </Route>
              <Route exact path="/sign-in" element={<PublicRoute authenticated={authentication.authenticated} />}>
                <Route path="/sign-in" element={<Login />} />
              </Route>
              <Route exact path="/" element={<IntelligentRoute authenticated={authentication.authenticated} />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
        </div>
      </div>
    </Router >
  );
}

export default App;
