import React, { Component } from 'react';
import logo from "../assets/logo.png";
class Logo extends Component {
    render() {
        return (
            <div className="logo-main">
                <img src={logo} />
            </div>
        )
    }
}

export default Logo;