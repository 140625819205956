import React from "react";
import Account from "./Account";
import Transactions from "./Transactions";

const Home = ({ transactions, account }) => {
  return (
    <div className="home">
      {account &&
        <>
          <Account account={account} />
          <Transactions
            transactions={transactions}
            transactionsToShow={3}
            link={"/transactions"}
            linkText={"Show All Transactions"}
          />
        </>
      }
    </div>
  );
};

export default Home;
